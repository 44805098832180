<template>
  <div class="inventory__container">
    <!--    <div class="department-item d-flex-center cursor-pointer" >-->
    <!--      -->
    <!--      -->
    <div class="cursor-pointer inventory__header" @click="$router.push('/inventory')">
      <md-list-item>
        <md-avatar>
          <img src="/img/sidebar/left/icon_aza.png">
        </md-avatar>
        <div class="md-list-item-text">
          <div class="title-sidebar">Kho hàng</div>
          <div class="heading-400" style="color: #E99B00;">
            AZA GROUP
          </div>
        </div>
      </md-list-item>
    </div>

    <div class="tab-wrapper mt-15px d-flex pl-15px">
      <div class="tab-btn input-search-field text-nowrap"
           @click="$modal.show('list-coupon-and-receipt-modal')">
        <span class="material-symbols-outlined">search</span>
        <span class="text-400-medium neutral_2">Tìm kiếm đơn, phiếu</span>
      </div>
      <!--      <div class="tab-btn ml-5px">-->
      <!--        <img src="/img/sidebar/left/messenger-icon.svg" alt="">-->
      <!--      </div>-->
      <!--      <div class="tab-btn ml-5px">-->
      <!--        <img src="/img/sidebar/left/chart-icon.svg" alt="">-->
      <!--      </div>-->
      <!--      <div class="tab-btn ml-5px">-->
      <!--        <img src="/img/sidebar/left/bag-icon.svg" alt="">-->
      <!--      </div>-->
    </div>

    <div class="text-500-semibold mt-20px pl-15px">
      Danh sách kho hàng
    </div>
    <div class="inventory-list-wrapper mt-10px">
      <div class="inventory-item-wrapper" v-for="item in inventoryList" :key="item.id">
        <div class="inventory-item inventory-parent-item" :class="{active: inventoryId && item.id == inventoryId}">
          <i @click="openSubMenu(item.id)" class="fa" aria-hidden="true" v-if="item.children"
             :class="showSubItem(item) ? 'fa-chevron-down' : 'fa-chevron-right'"></i>
          <!--          <div class="inventory-icon" style="background: url('/img/sidebar/left/kho-vietnam-icon.svg')"></div>-->
          <div class="colspan-menu" @click="clickToInventoryItem(item.id)">
            <md-icon class="inventory-icon">folder</md-icon>
            <div class="text-400-semibold ml-10px">{{ item.name }}</div>
          </div>
        </div>
        <div class="sub-inventory-item-wrapper" v-show="showSubItem(item)">
          <div class="inventory-item sub" :class="{active: inventoryId && subItem.id == inventoryId}"
               v-for="subItem in item.children" :key="'sub-item' + subItem.id"
               @click="clickToInventoryItem(subItem.id)">
            <i class="fa fa-circle" aria-hidden="true"></i>
            <!--            <div class="inventory-icon" style="background: url('/img/sidebar/left/kho-vietnam-icon.svg')"></div>-->
            <md-icon class="inventory-icon">folder</md-icon>
            <div class="text-400-medium ml-10px">{{ subItem.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import $ from "jquery";
import InventoryService from "../../../store/services/InventoryService";
import ProfileService from "../../../store/services/ProfileService";

export default {
  components: {},
  data() {
    return {
      tabActive: 1,
      positionList: [],
      keyword: "",
      inventoryList: [],
      inventoryId: this.$route.params.inventory_id,
      opened_inventory: []
    }
  },
  watch: {
    '$route.params.inventory_id'(newV) {
      this.inventoryId = newV;
    }
  },
  computed: {},
  created() {
    this.getInventories();
  },
  mounted() {
  },
  updated() {
  },
  methods: {
    async getInventories() {
      const userId = this.$store.state.auth.user.id;
      let inventory_department_role_scope;
      // await ProfileService.getProfile({user_id: userId}).then(res => {
      //   if (res) {
      //     inventory_department_role_scope = res.data.inventory_department_role_scope;
      //   }
      // });
      // if (!inventory_department_role_scope) {
      //   this.$router.push('/error')
      // }

      InventoryService.getInventories({user_id: userId}).then(res => {
        if (res) {
          this.inventoryList = res;
          localStorage.setItem("inventories", JSON.stringify(res));
          let inventoryParentIds = [];

          for (let i = 0; i < this.inventoryList.length; i++) {
            inventoryParentIds.push(this.inventoryList[i]['id']);
          }
          localStorage.setItem("inventoryParentIds", JSON.stringify(inventoryParentIds));
        }
      });
    },
    showSubItem(inventory) {
      if (this.opened_inventory.indexOf(inventory.id) > -1) {
        return true;
      }

      if (!this.inventoryId) {
        return false;
      }
      if (this.inventoryId == inventory.id) {
        return true;
      }

      if (inventory.children) {
        for (let i = 0; i < inventory.children.length; i++) {
          if (this.inventoryId == inventory.children[i].id) {
            return true;
          }
        }
      }

      return false;
    },

    openSubMenu(inventory_id) {
      // this.parent_id = inventory_id;


      if (this.opened_inventory.indexOf(inventory_id) > -1) {
        this.opened_inventory.splice(this.opened_inventory.indexOf(inventory_id), 1);
      } else {
        this.opened_inventory.push(inventory_id);
      }
      // if(this.opened_inventory_id !== null && this.opened_inventory_id == this.opened_inventory_id){
      //   this.opened_inventory_id = null
      // }else{
      //   this.opened_inventory_id = inventory_id
      // }
    },
    clickToInventoryItem(inventoryId) {
      let tab = this.$route.params.tab ? this.$route.params.tab : 'report';

      let queryDate = '';
      if(this.$route.query.start_date && this.$route.query.end_date){
        queryDate += `?start_date=${this.$route.query.start_date}&end_date=${this.$route.query.end_date}`;
      }

      this.$router.push(`/inventory/${inventoryId}/${tab}${queryDate}`);
    }
  },

};

</script>
<style lang="scss">
.inventory-list-wrapper {
  .colspan-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
}
</style>
